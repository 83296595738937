import Router from 'vue-router'

export default new Router({
    mode: 'hash',
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/verify/:req', name: 'verify', component: () => import( '@/views/verify.vue' ), props: true },
        { path: '/eula',        name: 'Eula',   component: () => import( '@/views/eula.vue' ) },
        { path: '/login',       name: 'Login',  component: () => import( '@/views/login.vue' ) },
        { path: '/signup',      name: 'Signup', component: () => import( '@/views/signup.vue' ) },
        { path: '/',                            component: () => import( '@/layouts/Main.vue' ),   children: [
            { path: '',           name: 'Dashboard',         component: () => import( '@/views/dashboard.vue' ) },
            { path: '/profile',   name: 'Profile',           component: () => import( '@/views/profile.vue' ) },
            { path: '/password',  name: 'Password',          component: () => import( '@/views/password.vue' ) },
            { path: '/library',   name: 'MediaLibrary',      component: () => import( '@/views/media_library.vue' ) },
            { path: '/dist',      name: 'MediaDistribution', component: () => import( '@/views/media_distribution.vue' ) },
            { path: '/transcode', name: 'MediaTranscode',    component: () => import( '@/views/media_transcode.vue' ) },
            { path: '/devices',   name: 'Devices',           component: () => import( '@/views/devices.vue' ) },
            { path: '/users',     name: 'Users',             component: () => import( '@/views/users.vue' ) },
            { path: '/orgs',      name: 'Organizations',     component: () => import( '@/views/orgs.vue' ) },
            { path: '/firmware',  name: 'Firmware',          component: () => import( '@/views/firmware.vue' ) },
            { path: '*',                                     component: () => import( '@/views/notfound.vue' ) }
        ]}
    ]
});
